$(function(){

  var swiper = new Swiper('.swiper-container', {
    speed: 500,
    loop: true,
    autoplay: false,
    effect: 'slide',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 1
    // coverflow: {
    //   rotate: 50,
    //   stretch: 0,
    //   depth: 100,
    //   modifier: 1,
    //   slideShadows : true
    // },
    // breakpoints: {
    //   767: {
    //     slidesPerView: 1,
    //     spaceBetween: 0
    //   }
    // }
  });

});
